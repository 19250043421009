<template>
  <v-app class="wmyHomeApp">
    <v-banner single-line class="banner">
      <v-img
        src="https://h5.ophyer.cn/official_website/other/wmyH5BigCustomer_banner.png"
        height="100%"
        :transition="false"
        @load="handleLoad"
        class="d-flex align-center"
        
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex mt-8" style="width:100%">
          <v-card color="transparent" flat style="width:100%">
            <div class="d-flex flex-no-wrap justify-space-between" style="width:100%">
              <div style="width:100%">
                <v-card-title class="white--text justify-center py-2 font-size-h5banner-title">
                  致力于品牌更深度的服务
                </v-card-title>
                <v-card-title class="white--text font-size-h5banner-tip justify-center py-1 text-align-center px-5">
                    技术+私域+运营赋能
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <v-card class="py-6" flat color="transparent">
        <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">{{data.module.title}}</v-card-title>
        <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">{{data.module.subtitle}}</v-card-text>
      <div class="mt-4">
        <v-row v-for="(row,rowIndex) in data.module.itemList" :key="rowIndex">
          <v-card  color="transparent" :class="['pa-3 d-flex h5-card',rowIndex % 2 == 0 ?'':'justify-flex-end']" width="100%" elevation="0" >
              <v-col cols="6" v-if="rowIndex % 2 == 0" >
                  <v-img class="flex-grow-0" :src="row.icon" contain position="top"></v-img>
              </v-col>
            <v-col cols="6">
                <div>
                <v-card-title class="pa-0 mt-2 font-size-15 line-height-20">
                    {{row.title}}
                </v-card-title>
                <!-- <v-card-text class="pa-0 mt-2 font-size-12 color-666" style="line-height:18px">
                    {{row.explain}}
                </v-card-text> -->
                </div>
            </v-col>
            <v-col cols="6" v-if="rowIndex % 2 != 0">
                <v-img class="flex-grow-0" :src="row.icon" contain position="top"></v-img>
            </v-col>
          </v-card>
        </v-row>
      </div>
    </v-card>
    <v-card class="py-6" flat color="transparent">
        <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">{{data.module1.title}}</v-card-title>
        <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">{{data.module1.subtitle}}</v-card-text>
      <div class="mt-4">
        <v-row>
            <v-col cols="6" v-for="(row,rowIndex) in data.module1.itemList" :key="rowIndex">
                <v-card flat height="100%" class="">
                        <div class="d-flex justify-center">
                            <v-img :src="row.src" contain width="100%"></v-img>
                        </div>
                        <v-card-title class="pa-2 font-size-15 line-height-22 justify-center text-align-center font-weight-bold">
                            {{row.title}}
                        </v-card-title>
                        <v-card-text class="px-2 text-align-center font-size-12 color-666 font-weight-300 line-height-17">
                            {{row.content}}
                        </v-card-text>
                </v-card>
            </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card class="py-6" flat color="transparent">
        <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">{{data.module2.title}}</v-card-title>
        <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">{{data.module2.subtitle}}</v-card-text>
      <div class="mt-4">
        <v-row>
            <v-col :cols="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 6 : 3" v-for="(row,rowIndex) in data.module2.itemList" :key="rowIndex">
                <v-card flat class="advantage_card">
                        <div class="img_title" justify="center">
                            <v-img :src="row.src" class="align-center rounded-lg" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                                <div class="white--text font-size-16 pl-6 pr-6  font-weight-bold text-align-center">{{row.title}}</div>
                            </v-img>
                        </div>
                </v-card>
            </v-col>
        </v-row>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import lavData from '@/static/lavData.js'
export default {
  name: 'solutionAR',
  components: {
  },
  data() {
    return {
      tab:0,
      data:lavData.h5BigCustomer,
      imgLoad: false,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleLoad() {
      this.imgLoad = true;
    },
    handleTabBtn: function (type){
      const maxTab = this.data.module.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    margin-top: -100px;
    .banner-text {
      p:nth-child(1) {
        font-size: 82px;
        //font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 88px;
      }
      p:nth-child(2) {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 100 !important;
        color: #fff;
      }
      .banner-btn {
        margin-top: 90px;
        width: 200px;
        height: 60px;
        background: #0568FD;
        border-radius: 35px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1200px !important;
    }
  }
}
.v-tab--active{
    b{
        color: #2181EA !important;
    }
}
.justify-flex-end{
    justify-content: flex-end;
}
.wmyHomeApp{
  background: #F5F8FE;
}
.advantage_card{
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    }
}
</style>
